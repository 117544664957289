import React, { useState, useEffect } from 'react';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';

import FightSelector from './FightSelector';
import FightConfirmModal from './FightConfirmModal';

import regionsList from '../../util/regions';

import styles from './FightMatcherContent.module.css'

const FightMatcherContent = () => {
  const data = useLoaderData();
  const navigate = useNavigate();
  const { fighterAId, sport } = useParams();
  const [fighterA, setFighterA] = useState(null);
  const [fighterB, setFighterB] = useState(null);
  const [gymA, setGymA] = useState(null);
  const [gymB, setGymB] = useState(null);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("activeFighters");
  const [preSelect, setPreselect] = useState(false);
  const weightClassMap = {
    "straw_weight": "Strawweight",
    "fly_weight": "Flyweight",
    "bantam_weight": "Bantamweight",
    "feather_weight": "Featherweight",
    "light_weight": "Lightweight",
    "welter_weight": "Welterweight",
    "middle_weight": "Middleweight",
    "light_heavy_weight": "Light Heavyweight",
    "heavy_weight": "Heavyweight"
  };

  const boxingWeightClassMap = {
    "light_fly_weight": "Light Flyweight",
    "flyweight": "Flyweight",
    "super_fly_weight": "Super Flyweight",
    "bantam_weight": "Bantamweight",
    "super_bantam_weight": "Super Bantamweight",
    "feather_weight": "Featherweight",
    "super_feather_weight": "Super Featherweight",
    "light_weight": "Lightweight",
    "super_light_weight": "Super Lightweight",
    "welter_weight": "Welterweight",
    "super_welter_weight": "Super Welterweight",
    "middle_weight": "Middleweight",
    "super_middle_weight": "Super Middleweight",
    "light_heavy_weight": "Light Heavyweight",
    "cruiser_weight": "Cruiserweight",
    "heavy_weight": "Heavyweight"
  };

  const [catchWeight, setCatchWeight] = useState(false);
  const [catchWeightRange, setCatchWeightRange] = useState({ min: 0.0, max: 500.0 });
  const [weightClass, setWeightClass] = useState("NULL");
  const [gender, setGender] = useState("NULL");
  const [professional, setProfessional] = useState("NULL");
  const [region, setRegion] = useState("NULL");


  useEffect(() => {
    if (fighterAId) {
      const preselectedFighter = data.activeFighters.filter(activeFighter => activeFighter.id === fighterAId)[0];
      handleSelectFighterA(preselectedFighter);
      setPreselect(true);
    }
  }, [fighterAId, data.activeFighters]);

  useEffect(() => {
    if (fighterA && fighterB) {
      setIsConfirmOpen(true);
    }
  }, [fighterA, fighterB, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCloseModal = () => {
    setIsConfirmOpen(false);
    setFighterA(null);
    setFighterB(null);
    setGymA(null);
    setGymB(null);
    setWeightClass("NULL");
    setCatchWeightRange({min: 0.0, max: 500.0})
    setGender("NULL");
    setProfessional("NULL");
    setRegion("NULL");
  };

  const handleWeightClassSelect = (id) => {
    setWeightClass(id);
    if (fighterA && fighterA.stats.weight_class !== id) {
      setFighterA(null);
    }
    if (fighterB && fighterB.stats.weight_class !== id) {
      setFighterB(null);
    }
  };

  const handleSelectFighterA = (fighter) => {
    setFighterA(fighter);
    setGymA(fighter.gym_id);
    setWeightClass(fighter.stats.weight_class);
    setGender(fighter.gender);
    setProfessional(fighter.stats.professional ? "PROFESSIONAL" : "AMATEUR");
    if (fighter.stats.catch_weight_range) {
      setCatchWeightRange(fighter.stats.catch_weight_range);
    }
  }

  const handleDeselectFighterA = () => {
    setFighterA(null);
    setGymA(null);
    setPreselect(false);
    if (!fighterB) {
      setCatchWeightRange({ min: 0.0, max: 500.0 });
      setWeightClass("NULL");
      setGender("NULL");
      setProfessional("NULL");
      setRegion("NULL");
    }
  }

  const handleSelectFighterB = (fighter) => {
    setFighterB(fighter);
    setGymB(fighter.gym_id);
    setWeightClass(fighter.stats.weight_class);
    setGender(fighter.gender);
    setProfessional(fighter.stats.professional ? "PROFESSIONAL" : "AMATEUR");
    if (fighter.stats.catch_weight) {
      setCatchWeightRange({ min: fighter.stats.catch_weight.min, max: fighter.stats.catch_weight.max });
    }
  }

  const handleDeselectFighterB = () => {
    setFighterB(null);
    setGymB(null);
    if (!fighterA) {
      setCatchWeightRange({ min: 0.0, max: 500.0 });
      setWeightClass("NULL");
      setGender("NULL");
      setProfessional("NULL");
      setRegion("NULL");
    }
  }

  return (
    <div className={styles.container}>
      <h1>Selected Sport: {sport.replace(/_/g, ' ').toUpperCase()}</h1>
      {isConfirmOpen && (
        <FightConfirmModal
          title="Request Match?"
          onClose={handleCloseModal}
          fighterA={fighterA}
          fighterB={fighterB}
          catchWeight={catchWeight}
          weightClass={weightClass}
          professional={professional}
        />
      )}
      <div className={styles.filtersContainer}>
        <div>
          <label htmlFor="catchWeight">Catch Weight?</label>
          <select id="catchWeight" name="catchWeight" value={catchWeight} onChange={(event) => setCatchWeight(event.target.value === 'true')}>
            <option value={false}>No</option>
            <option value={true}>Yes</option>
          </select>
        </div>
        {catchWeight &&
          <div className={styles.catchWeightRange}>
            <div>
              <label htmlFor="catchWeightMin">Min (KG)</label>
              <input
                id="catchWeightMin"
                type="number"
                min={0}
                step="any"
                name="catchWeightMin"
                value={catchWeightRange.min}
                disabled={fighterA || fighterB}
                onChange={(event) => setCatchWeightRange({ ...catchWeightRange, min: event.target.value })}
                required
              />
            </div>
            <div>
              <label htmlFor="catchWeightMax">Max (KG)</label>
              <input
                id="catchWeightMax"
                type="number"
                min={0}
                step="any"
                name="catchWeightMax"
                value={catchWeightRange.max}
                disabled={fighterA || fighterB}
                onChange={(event) => setCatchWeightRange({ ...catchWeightRange, max: event.target.value })}
                required
              />
            </div>
          </div>}
        {!catchWeight && <div>
          <label htmlFor="weightClass">Weight Class</label>
          <select id="weightClass" name="weightClass" value={weightClass} disabled={fighterA || fighterB} onChange={(event) => handleWeightClassSelect(event.target.value)}>
            <option value="NULL">Select a weight class</option>
            {Object.keys(sport === 'boxing' ? boxingWeightClassMap : weightClassMap).map((key) => (
              <option key={key} value={key}>{sport === 'boxing' ? boxingWeightClassMap[key] : weightClassMap[key]}</option>
            ))}
          </select>
        </div>}
        <div>
          <label htmlFor="gender">Gender</label>
          <select id="gender" name="gender" value={gender} disabled={fighterA || fighterB} onChange={(event) => setGender(event.target.value)}>
            <option value="NULL">Select a gender</option>
            <option value="MALE">Male</option>
            <option value="FEMALE">Female</option>
          </select>
        </div>
        <div>
          <label htmlFor="professional">Professional</label>
          <select id="professional" name="professional" value={professional} disabled={fighterA || fighterB} onChange={(event) => setProfessional(event.target.value)}>
            <option value="NULL">Professional or Amateur?</option>
            <option value="AMATEUR">Amateur</option>
            <option value="PROFESSIONAL">Professional</option>
          </select>
        </div>
        <div>
          <label htmlFor="region">Region</label>
          <select
            id="region"
            name="region"
            value={region}
            onChange={(e) => setRegion(e.target.value)}
          >
            <option key="NULL" value="NULL">Select a region</option>
            {regionsList.map((regionName) => (
              <option key={regionName} value={regionName}>
                {regionName}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className={styles.tabsContainer}>
        <button
          className={`${styles.tab} ${activeTab === "activeFighters" ? styles.activeTab : ""}`}
          onClick={() => setActiveTab("activeFighters")}
        >
          Active Fighters
        </button>
        <button
          className={`${styles.tab} ${activeTab === "submittedFighters" ? styles.activeTab : ""}`}
          onClick={() => setActiveTab("submittedFighters")}
        >
          Submitted Fighters
        </button>
      </div>
      <div className={styles.splitContainer}>
        <div className={`${styles.split} ${!fighterA ? styles.active : ''}`}>
          <FightSelector
            data={data}
            title="Select Fighter A"
            handleSelect={handleSelectFighterA}
            handleDeselect={handleDeselectFighterA}
            currentTab={activeTab}
            fighterB={fighterB ? fighterB.id : null}
            gymB={gymB}
            selectedFighter={fighterA ? fighterA.id : null}
            catchWeight={catchWeight}
            catchWeightRange={catchWeightRange}
            weightClass={weightClass}
            gender={gender}
            professional={professional}
            region={region}
            preSelect={preSelect} />
        </div>
        <div className={`${styles.split} ${fighterA ? styles.active : ''}`}>
          <h4 onClick={handleDeselectFighterA} className={`${styles.mobile} ${styles.reselect}`}>
            {`<< Change Fighter A`}
          </h4>
          {fighterA && <h3 className={styles.mobile}> Matching for {`${fighterA.first_name} ${fighterA.nickname ? `"${fighterA.nickname}"` : ''} ${fighterA.last_name}`}</h3>}
          <FightSelector
            data={data}
            title="Select Fighter B"
            handleSelect={handleSelectFighterB}
            handleDeselect={handleDeselectFighterB}
            currentTab={activeTab}
            fighterA={fighterA ? fighterA.id : null}
            gymA={gymA}
            selectedFighter={fighterB ? fighterB.id : null}
            catchWeight={catchWeight}
            catchWeightRange={catchWeightRange}
            weightClass={weightClass}
            gender={gender}
            professional={professional}
            region={region} />
        </div>
      </div>
    </div>
  );
}

export default FightMatcherContent;
import { Form, Link, useSubmit } from 'react-router-dom';
import { removeToken } from '../../util/auth';
import { useSocket } from '../../context/SocketContext';
import Footer from "../ui/Footer";

import styles from './LogoutForm.module.css';

const LogoutForm = () => {
  const submit = useSubmit();
  const { connectWebSocket, clearNotifications } = useSocket();

  const handleLogout = (event) => {
    event.preventDefault();
    removeToken();
    connectWebSocket();
    clearNotifications();
    submit(event.target, { method: 'post' });
  }

  return (
    <>
      <Form method="post" className={styles.form}>
        <h1>Confirm Logout</h1>
        <p>Are you sure you wish to logout?</p>
        <div className={styles.actions}>
          <Link to='/'>
            Cancel
          </Link>
          <button onClick={handleLogout}>
            Confirm
          </button>
        </div>
      </Form>
      <Footer />
    </>
  );
}

export default LogoutForm;
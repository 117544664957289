import { useState, useEffect } from 'react';
import {
  Form,
  useLocation,
  useLoaderData,
  useActionData,
  useNavigation,
  Link
} from 'react-router-dom';
import moment from 'moment-timezone';

import styles from './FighterForm.module.css';

const FighterForm = () => {
  const formatDate = (date) => {
    return moment(date).format('yyyy-MM-DD');
  }

  const navigation = useNavigation();
  const isSubmitting = navigation.state === 'submitting';
  const data = useLoaderData();
  const actionData = useActionData()
  const location = useLocation();
  const isAddFighter = location.pathname === '/dashboard/add-fighter';

  const [firstName, setFirstName] = useState(data?.first_name || "");
  const [lastName, setLastName] = useState(data?.last_name || "");
  const [nickname, setNickname] = useState(data?.nickname || "");
  const [dob, setDob] = useState(data?.dob ? formatDate(data.dob) : '1990-01-01');
  const [height, setHeight] = useState(data?.height || "");
  const [reach, setReach] = useState(data?.reach || "");
  const [gender, setGender] = useState(data?.gender || "MALE");
  const [active, setActive] = useState(data?.active ?? true);
  const [tapologyUrl, setTapologyUrl] = useState(data?.tapology_url || "");

  const [selectedSports, setSelectedSports] = useState({
    MMA: data?.mma_stats ? 'yes' : 'no',
    Boxing: data?.boxing_stats ? 'yes' : 'no',
    K1: data?.k1_stats ? 'yes' : 'no',
    MuayThai: data?.muay_thai_stats ? 'yes' : 'no',
    Grappling: data?.grappling_stats ? 'yes' : 'no'
  });

  const [selectedCatchWeights, setSelectedCatchWeights] = useState({
    MMA: data?.mma_stats?.catch_weight_range ? 'yes' : 'no',
    Boxing: data?.boxing_stats?.catch_weight_range ? 'yes' : 'no',
    K1: data?.k1_stats?.catch_weight_range ? 'yes' : 'no',
    MuayThai: data?.muay_thai_stats?.catch_weight_range ? 'yes' : 'no',
    Grappling: data?.grappling_stats?.catch_weight_range ? 'yes' : 'no'
  });


  const [stats, setStats] = useState({
    MMA: data?.mma_stats || { weight_class: "", catch_weight_range: { min: 0.0, max: 0.0 }, years_experience: 0, professional: false, amateur_record: { wins: 0, losses: 0, draws: 0 }, pro_record: { wins: 0, losses: 0, draws: 0 } },
    Boxing: data?.boxing_stats || { weight_class: "", catch_weight_range: { min: 0.0, max: 0.0 }, years_experience: 0, professional: false, amateur_record: { wins: 0, losses: 0, draws: 0 }, pro_record: { wins: 0, losses: 0, draws: 0 } },
    K1: data?.k1_stats || { weight_class: "", catch_weight_range: { min: 0.0, max: 0.0 }, years_experience: 0, professional: false, amateur_record: { wins: 0, losses: 0, draws: 0 }, pro_record: { wins: 0, losses: 0, draws: 0 } },
    MuayThai: data?.muay_thai_stats || { weight_class: "", catch_weight_range: { min: 0.0, max: 0.0 }, years_experience: 0, professional: false, amateur_record: { wins: 0, losses: 0, draws: 0 }, pro_record: { wins: 0, losses: 0, draws: 0 } },
    Grappling: data?.grappling_stats || { weight_class: "", catch_weight_range: { min: 0.0, max: 0.0 }, years_experience: 0, professional: false, amateur_record: { wins: 0, losses: 0, draws: 0 }, pro_record: { wins: 0, losses: 0, draws: 0 } }
  });

  const weightClassMap = {
    "straw_weight": "Strawweight",
    "fly_weight": "Flyweight",
    "bantam_weight": "Bantamweight",
    "feather_weight": "Featherweight",
    "light_weight": "Lightweight",
    "welter_weight": "Welterweight",
    "middle_weight": "Middleweight",
    "light_heavy_weight": "Light Heavyweight",
    "heavy_weight": "Heavyweight"
  };

  const boxingWeightClassMap = {
    "light_fly_weight": "Light Flyweight",
    "flyweight": "Flyweight",
    "super_fly_weight": "Super Flyweight",
    "bantam_weight": "Bantamweight",
    "super_bantam_weight": "Super Bantamweight",
    "feather_weight": "Featherweight",
    "super_feather_weight": "Super Featherweight",
    "light_weight": "Lightweight",
    "super_light_weight": "Super Lightweight",
    "welter_weight": "Welterweight",
    "super_welter_weight": "Super Welterweight",
    "middle_weight": "Middleweight",
    "super_middle_weight": "Super Middleweight",
    "light_heavy_weight": "Light Heavyweight",
    "cruiser_weight": "Cruiserweight",
    "heavy_weight": "Heavyweight"
  };

  const sportDisplayNames = {
    MMA: "MMA",
    Boxing: "Boxing",
    K1: "K1",
    MuayThai: "Muay Thai",
    Grappling: "Grappling"
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (actionData) {
    window.scrollTo(0, 0);
  }

  const handleSportSelection = (sport, value) => {
    setSelectedSports(prev => ({ ...prev, [sport]: value }));
  };

  const handleCatchWeightSelection = (sport, value) => {
    setSelectedCatchWeights(prev => ({ ...prev, [sport]: value }));
  };

  const handleStatsChange = (sport, field, value) => {
    if (field === 'catch_weight_range') {
      setStats(prev => ({
        ...prev,
        [sport]: {
          ...prev[sport],
          catch_weight_range: {
            ...prev[sport].catch_weight_range,
            ...value // Merging the new values for min or max
          }
        }
      }));
    } else {
      setStats(prev => ({
        ...prev,
        [sport]: {
          ...prev[sport],
          [field]: value
        }
      }));
    }
  };

  const handleRecordChange = (sport, recordType, field, value) => {
    setStats(prev => ({
      ...prev,
      [sport]: {
        ...prev[sport],
        [recordType]: {
          ...prev[sport][recordType],
          [field]: parseInt(value)
        }
      }
    }));
  };

  const renderWeightClassOptions = (sport) => {
    const map = sport === 'Boxing' ? boxingWeightClassMap : weightClassMap;
    return Object.keys(map).map(key => (
      <option key={key} value={key}>{map[key]}</option>
    ));
  };

  return (
    <>
      <div className={styles.background} />
      <div className={styles.container}>
        <Form method="post" className={styles.form}>
          <img className={styles.authlogo} src={process.env.PUBLIC_URL + '/authlogo.png'} alt="Logo" />
          <h1>{isAddFighter ? 'Add Fighter' : 'Edit Fighter'}</h1>
          {actionData && actionData.errors && (
            <div className={styles.errors}>
              <ul>
                {Object.values(actionData.errors).map((err) => (
                  <li key={err}>{err}</li>
                ))}
              </ul>
            </div>
          )}
          {actionData && actionData.message &&
            <div className={actionData.success ? styles.success : styles.errors}>
              <p>{actionData.message}</p>
            </div>
          }
          <p>
            <label htmlFor="firstName">First Name</label>
            <input id="firstName" type="text" name="firstName" value={firstName} onChange={(event) => setFirstName(event.target.value)} required />
          </p>

          <p>
            <label htmlFor="lastName">Last Name</label>
            <input id="lastName" type="text" name="lastName" value={lastName} onChange={(event) => setLastName(event.target.value)} required />
          </p>
          {!isAddFighter && <p>
            <label>Fighter Picture</label>
            {data.image_url ? (
              <img className={styles.fighterPicture} src={`${data.image_url}?timestamp=${new Date().getTime()}`} alt="FighterPicture" />
            ) : null}
            <Link className={styles.link} to='upload-image'>
              {data.image_url ? "Click here to change the fighter picture" : "Click here to upload a fighter picture"}
            </Link>
          </p>}

          <p>
            <label htmlFor="nickname">Nickname - Optional</label>
            <input id="nickname" type="text" name="nickname" value={nickname} onChange={(event) => setNickname(event.target.value)} />
          </p>

          <p>
            <label htmlFor="dob">Date of Birth: </label>
            <input
              type="date"
              id="dob"
              name="dob"
              value={dob}
              onChange={(event) => setDob(event.target.value)}
              required
            />
          </p>

          <p>
            <label htmlFor="height">Height (cm) - Optional</label>
            <input id="height" type="number" name="height" min={0} value={height} onChange={(event) => setHeight(event.target.value)} />
          </p>

          <p>
            <label htmlFor="reach">Reach (cm) - Optional</label>
            <input id="reach" type="number" name="reach" min={0} value={reach} onChange={(event) => setReach(event.target.value)} />
          </p>

          <p>
            <label htmlFor="gender">Gender</label>
            <select id="gender" name="gender" value={gender} onChange={(event) => setGender(event.target.value)} required>
              <option value="MALE">Male</option>
              <option value="FEMALE">Female</option>
            </select>
          </p>
          <hr />

          {['MMA', 'Boxing', 'K1', 'MuayThai', 'Grappling'].map(sport => (
            <div key={sport} className={styles.sportsContainer}>
              <label htmlFor={`${sport}_participation`}>{sportDisplayNames[sport]}</label>
              <select
                id={`${sport}_participation`}
                name={`${sport}_participation`}
                value={selectedSports[sport]}
                onChange={(e) => handleSportSelection(sport, e.target.value)}
                required
              >
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
              {selectedSports[sport] === 'yes' && (
                <div className={styles.statInputs}>
                  <p>
                    <label htmlFor={`${sport}_weightClass`}>Weight Class</label>
                    <select
                      id={`${sport}_weightClass`}
                      name={`${sport}_weightClass`}
                      value={stats[sport].weight_class}
                      onChange={(e) => handleStatsChange(sport, 'weight_class', e.target.value)}
                      required
                    >
                      {renderWeightClassOptions(sport)}
                    </select>
                  </p>

                  <p>
                    <label htmlFor={`${sport}_catchWeight`}>Catch Weight Range</label>
                    <select
                      id={`${sport}_catchWeight`}
                      name={`${sport}_catchWeight`}
                      value={selectedCatchWeights[sport]}
                      onChange={(e) => handleCatchWeightSelection(sport, e.target.value)}
                      required
                    >
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </p>

                  {selectedCatchWeights[sport] === 'yes' && (
                    <div className={styles.catchWeightRange}>
                      <label htmlFor={`${sport}_catchWeightMin`}>Min (KG)</label>
                      <input
                        id={`${sport}_catchWeightMin`}
                        type="number"
                        min={0}
                        step="any"
                        name={`${sport}_catchWeightMin`}
                        value={stats[sport].catch_weight_range?.min}
                        onChange={(e) => handleStatsChange(sport, 'catch_weight_range', { ...stats[sport].catch_weight_range, min: e.target.value })}
                        required
                      />
                      <label htmlFor={`${sport}_catchWeightMax`}>Max (KG)</label>
                      <input
                        id={`${sport}_catchWeightMax`}
                        type="number"
                        min={0}
                        step="any"
                        name={`${sport}_catchWeightMax`}
                        value={stats[sport].catch_weight_range?.max}
                        onChange={(e) => handleStatsChange(sport, 'catch_weight_range', { ...stats[sport].catch_weight_range, max: e.target.value })}
                        required
                      />
                    </div>
                  )}

                  <p>
                    <label htmlFor={`${sport}_yearsExperience`}>Years Experience</label>
                    <input
                      id={`${sport}_yearsExperience`}
                      type="number"
                      min={0}
                      name={`${sport}_yearsExperience`}
                      value={stats[sport].years_experience}
                      onChange={(e) => handleStatsChange(sport, 'years_experience', e.target.value)}
                      required
                    />
                  </p>

                  <p>
                    <label htmlFor={`${sport}_professional`}>Professional</label>
                    <select
                      id={`${sport}_professional`}
                      name={`${sport}_professional`}
                      value={stats[sport].professional}
                      onChange={(e) => handleStatsChange(sport, 'professional', e.target.value === 'true')}
                      required
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                  </p>

                  <p>
                    <label htmlFor={`${sport}_amateurWins`}>Amateur Record - Wins</label>
                    <input
                      id={`${sport}_amateurWins`}
                      type="number"
                      min={0}
                      name={`${sport}_amateurWins`}
                      value={stats[sport].amateur_record.wins}
                      onChange={(e) => handleRecordChange(sport, 'amateur_record', 'wins', e.target.value)}
                      required
                    />
                  </p>

                  <p>
                    <label htmlFor={`${sport}_amateurLosses`}>Amateur Record - Losses</label>
                    <input
                      id={`${sport}_amateurLosses`}
                      type="number"
                      min={0}
                      name={`${sport}_amateurLosses`}
                      value={stats[sport].amateur_record.losses}
                      onChange={(e) => handleRecordChange(sport, 'amateur_record', 'losses', e.target.value)}
                      required
                    />
                  </p>

                  <p>
                    <label htmlFor={`${sport}_amateurDraws`}>Amateur Record - Draws</label>
                    <input
                      id={`${sport}_amateurDraws`}
                      type="number"
                      min={0}
                      name={`${sport}_amateurDraws`}
                      value={stats[sport].amateur_record.draws}
                      onChange={(e) => handleRecordChange(sport, 'amateur_record', 'draws', e.target.value)}
                      required
                    />
                  </p>

                  {stats[sport]?.professional && (
                    <>
                      <p>
                        <label htmlFor={`${sport}_proWins`}>Professional Record - Wins</label>
                        <input
                          id={`${sport}_proWins`}
                          type="number"
                          min={0}
                          name={`${sport}_proWins`}
                          value={stats[sport].pro_record.wins}
                          onChange={(e) => handleRecordChange(sport, 'pro_record', 'wins', e.target.value)}
                          required
                        />
                      </p>

                      <p>
                        <label htmlFor={`${sport}_proLosses`}>Professional Record - Losses</label>
                        <input
                          id={`${sport}_proLosses`}
                          type="number"
                          min={0}
                          name={`${sport}_proLosses`}
                          value={stats[sport].pro_record.losses}
                          onChange={(e) => handleRecordChange(sport, 'pro_record', 'losses', e.target.value)}
                          required
                        />
                      </p>

                      <p>
                        <label htmlFor={`${sport}_proDraws`}>Professional Record - Draws</label>
                        <input
                          id={`${sport}_proDraws`}
                          type="number"
                          min={0}
                          name={`${sport}_proDraws`}
                          value={stats[sport].pro_record.draws}
                          onChange={(e) => handleRecordChange(sport, 'pro_record', 'draws', e.target.value)}
                          required
                        />
                      </p>
                    </>
                  )}
                </div>
              )}
            </div>
          ))}

          <p>
            <label htmlFor="active">Active</label>
            <select id="active" name="active" value={active} onChange={(event) => setActive(event.target.value === 'true')} required>
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </select>
          </p>

          <p>
            <label htmlFor="tapologyUrl">Tapology Link - Optional</label>
            <input id="tapologyUrl" type="text" name="tapologyUrl" value={tapologyUrl} onChange={(event) => setTapologyUrl(event.target.value)} />
          </p>

          <div className={styles.actions}>
            <button disabled={isSubmitting}>
              {isSubmitting ? 'Submitting...' : isAddFighter ? 'Add Fighter' : 'Update Fighter'}
            </button>
          </div>
        </Form>
      </div>
    </>
  );
}

export default FighterForm;

import { useState, useEffect } from 'react';
import { useLoaderData, useRouteLoaderData, Link, useLocation } from 'react-router-dom';
import { decodeToken } from '../../util/auth';
import moment from 'moment-timezone';

import FightCard from './FightCard';
import FighterStatCard from './FighterStatCard';
import Footer from "../ui/Footer";

import styles from './FighterContent.module.css';

const FighterContent = () => {
  const token = useRouteLoaderData('root');
  const fighter = useLoaderData();
  const location = useLocation();

  const [activeTab, setActiveTab] = useState("fighterStats");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const weightClassMap = {
    "straw_weight": "Strawweight",
    "light_fly_weight": "Light Flyweight",
    "fly_weight": "Flyweight",
    "super_fly_weight": "Super Flyweight",
    "bantam_weight": "Bantamweight",
    "super_bantam_weight": "Super Bantamweight",
    "feather_weight": "Featherweight",
    "super_feather_weight": "Super Featherweight",
    "light_weight": "Lightweight",
    "super_light_weight": "Super Lightweight",
    "welter_weight": "Welterweight",
    "super_welter_weight": "Super Welterweight",
    "middle_weight": "Middleweight",
    "super_middle_weight": "Super Middleweight",
    "light_heavy_weight": "Light Heavyweight",
    "cruiser_weight": "Cruiserweight",
    "heavy_weight": "Heavyweight"
  };

  const isFutureFight = (dateString) => {
    const convertedTime = moment.utc(dateString).tz('Europe/London');
    const eventDate = convertedTime.toDate();
    eventDate.setHours(0, 0, 0, 0); // ignore time

    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // ignore time

    return eventDate > currentDate;
  }

  let decodedToken = null;
  if (token) {
    decodedToken = decodeToken(token);
  }

  const fighterPictureSrc = fighter.image_url ? fighter.image_url : process.env.PUBLIC_URL + '/authlogo.png';

  const renderStats = (stats, sport) => {
    if (!stats) return null;
    const weightClass = weightClassMap[stats.weight_class];

    return (
      <ul className={styles.statsSection}>
        <FighterStatCard sport={sport} weightClass={weightClass} stats={stats} />
      </ul>
    );
  };

  const calculateAge = (dobString) => {
    // Parse the date of birth string to a Date object
    const dob = new Date(dobString);

    // Get the current date
    const today = new Date();

    // Calculate the difference in years
    let age = today.getFullYear() - dob.getFullYear();

    // Adjust age if the birthday has not occurred yet this year
    const monthDifference = today.getMonth() - dob.getMonth();
    const dayDifference = today.getDate() - dob.getDate();

    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      age--;
    }

    return age;
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.logoSection}>
            <img className={styles.fighterPicture} src={`${fighterPictureSrc}?timestamp=${new Date().getTime()}`} alt="FighterPicture" />
          </div>
          <div className={styles.titleSection}>
            <h1>{fighter.first_name}{fighter.nickname ? ` '${fighter.nickname}'` : ''} {fighter.last_name}</h1>
            <ul className={styles.info}>
              <li>Gender: {fighter.gender}</li>
              {fighter.dob && <li>Age: {calculateAge(fighter.dob)}</li>}
              {!fighter.dob && <li>Age: N/A</li>}
              {fighter.height > 0 && <li>Height: {fighter.height}cm</li>}
              {fighter.reach > 0 && <li>Reach: {fighter.reach}cm</li>}
              {(fighter.height > 0 || fighter.reach) > 0 && <br />}
              <li>
                <div className={styles.infoLinks}>
                  {fighter.tapology_url &&
                    <a href={fighter.tapology_url.startsWith('http://') || fighter.tapology_url.startsWith('https://') ? fighter.tapology_url : `https://${fighter.tapology_url}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Tapology
                    </a>}
                  <Link to={`/gym/${fighter.gym_id}`}>
                    View Gym
                  </Link>
                </div>
              </li>
            </ul>
            {decodedToken && decodedToken.activeRoleUserId === fighter.gym_id &&
              <div className={styles.actionButtons}>
                <Link to={'/dashboard/edit-fighter/' + fighter.id}>
                  Edit Fighter
                </Link>
                <Link to={'delete'}>
                  Delete Fighter
                </Link>
              </div>
            }
            {fighter.active && decodedToken && decodedToken.authorities[0] === 'ROLE_PROMOTION' &&
              <div className={styles.actionButtons}>
                <Link to={'request-match'}>
                  Request Match
                </Link>
              </div>
            }
            <hr />
            <h2>{fighter.fights.length} TRACKED FIGHT{fighter.fights.length !== 1 ? 'S' : ''}</h2>
          </div>
        </div>
        <div className={styles.tabsContainer}>
          <button
            className={`${styles.tab} ${activeTab === "fighterStats" ? styles.activeTab : ""}`}
            onClick={() => setActiveTab("fighterStats")}
          >
            Fighter Statistics
          </button>
          <button
            className={`${styles.tab} ${activeTab === "pastFights" ? styles.activeTab : ""}`}
            onClick={() => setActiveTab("pastFights")}
          >
            Previous Fights
          </button>
          <button
            className={`${styles.tab} ${activeTab === "upcomingFights" ? styles.activeTab : ""}`}
            onClick={() => setActiveTab("upcomingFights")}
          >
            Upcoming Fights
          </button>
        </div>
        {activeTab === "fighterStats" && (
          <div className={styles.statsContainer}>
            {renderStats(fighter.mma_stats, 'MMA')}
            {renderStats(fighter.boxing_stats, 'Boxing')}
            {renderStats(fighter.k1_stats, 'K1')}
            {renderStats(fighter.muay_thai_stats, 'Muay Thai')}
            {renderStats(fighter.grappling_stats, 'Grappling')}
          </div>
        )}
        {activeTab === "pastFights" && (
          <ul className={styles.fightsList}>
            {fighter.fights.filter(fight => !isFutureFight(fight.date_time)).map(fight => (
              <li key={fight.id} className={styles.fightsListItem}>
                <FightCard fight={fight} />
              </li>
            ))}
          </ul>
        )}
        {activeTab === "upcomingFights" && (
          <ul className={styles.fightsList}>
            {fighter.fights.filter(fight => isFutureFight(fight.date_time)).map(fight => (
              <li key={fight.id} className={styles.fightsListItem}>
                <FightCard fight={fight} />
              </li>
            ))}
          </ul>
        )}
      </div>
      <Footer />
    </>
  );
}

export default FighterContent;
import { json, redirect } from 'react-router-dom';
import { geocodeAddress } from '../util/maps';

import AuthForm from '../components/users/AuthForm';

const Register = () => {
  return (
    <AuthForm />
  );
}

const validateForm = (regData) => {
  let validationErrors = [];
  if (regData.orgType === 'NULL') {
    validationErrors.push('Please select an organisation type from the dropdown.');
  }
  if (regData.orgType === 'gym' && (!regData.address || regData.address === null || regData.address === '')) {
    validationErrors.push('Please enter an address.');
  }
  if (regData.orgType === 'gym' && (!regData.region || regData.region === '')) {
    validationErrors.push('Address invalid (does not contain a county/province).');
  }
  if (regData.password.length < 8) {
    validationErrors.push('Password must be at least 8 characters.');
  }
  if (!/[A-Z]/.test(regData.password)) {
    validationErrors.push('Password must contain at least 1 upper case letter.');
  }
  if (!/[a-z]/.test(regData.password)) {
    validationErrors.push('Password must contain at least 1 lower case letter.');
  }
  if (regData.password !== regData.confirmPassword) {
    validationErrors.push('Password and confirm password do not match.')
  }
  if (!/^\d{10}$/.test(regData.phoneNumber)) {
    validationErrors.push("This is not a valid phone number.");
  }
  return validationErrors;
}

export default Register;

export const action = async ({ request }) => {
  const data = await request.formData();

  const regData = {
    email: data.get('email'),
    password: data.get('password'),
    confirmPassword: data.get('confirmPassword'),
    orgName: data.get('orgName'),
    orgType: data.get('orgType'),
    address: data.get('address'),
    region: data.get('province'),
    contactEmail: data.get('contactEmail'),
    areaCode: data.get('areaCode'),
    phoneNumber: data.get('phoneNumber').replace(/^(?:\+440|440|44|\+44|0)/, '').replace(/\s+/g, '')
  }

  const validationErrors = validateForm(regData);
  if (validationErrors.length > 0) {
    return { errors: validationErrors }
  }

  const authData = {
    username: regData.email,
    password: regData.password,
    role: regData.orgType === 'gym' ? 'ROLE_GYM' : 'ROLE_PROMOTION',
    organisation: regData.orgName,
    contact_email: regData.contactEmail,
    area_code: regData.areaCode,
    phone_number: regData.phoneNumber
  }

  if (regData.address) {
    const geoLocation = await geocodeAddress(regData.address);
    authData.address = regData.address;
    authData.region = regData.region;
    authData.coordinates = {
      latitude: geoLocation.Latitude,
      longitude: geoLocation.Longitude
    }
  }

  const authUrl = process.env.REACT_APP_AUTH_URL
  const authResponse = await fetch(authUrl + '/api/v1/auth/register', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(authData)
  });

  const authResData = await authResponse.json();

  if (authResponse.status === 401 || authResponse.status === 403 || authResponse.status === 404 || authResponse.status === 409) {
    return authResData;
  }

  if (!authResponse.ok) {
    throw json({ message: authResData.message }, { status: 500 });
  }

  return redirect('/email-unconfirmed/' + regData.email);
}
import { useState, useEffect } from 'react';
import { Form, useActionData } from 'react-router-dom';

import Modal from '../ui/Modal';

import styles from './FightConfirmModal.module.css';

const FightConfirmModal = ({ professional, catchWeight, weightClass, fighterA, fighterB, title, onClose }) => {
  const data = useActionData();
  const [selectedCatchWeight, setSelectedCatchWeight] = useState(0);
  const [titleFight, setTitleFight] = useState(false);
  const [localError, setLocalError] = useState(null);

  function findOverlapRange(fighterA, fighterB) {
    const minA = fighterA.stats.catch_weight_range.min;
    const maxA = fighterA.stats.catch_weight_range.max;
    const minB = fighterB.stats.catch_weight_range.min;
    const maxB = fighterB.stats.catch_weight_range.max;

    const newMin = Math.max(minA, minB);
    const newMax = Math.min(maxA, maxB);

    // Ensure that the newMin is less than or equal to newMax
    if (newMin <= newMax) {
      return { min: newMin, max: newMax };
    } else {
      // Return null or an empty object if there's no overlap
      return null;
    }
  }

  const catchWeightRange = catchWeight ? findOverlapRange(fighterA, fighterB) : null;

  useEffect(() => {
    if (catchWeight) {
      const catchWeightRange = findOverlapRange(fighterA, fighterB);
      if (catchWeightRange) {
        setSelectedCatchWeight((catchWeightRange.min + catchWeightRange.max) / 2);
      }
    }
  }, [catchWeight, fighterA, fighterB]);

  useEffect(() => {
    setLocalError(data?.message || null);
  }, [data]);

  const handleClose = () => {
    setLocalError(null);
    onClose();
  };

  return (
    <Modal onClose={handleClose}>
      <Form method="post" className={styles.confirm}>
        {localError && (
          <div className={styles.errors}>
            <p>{localError}</p>
          </div>
        )}
        <h2>{title ? title : 'Are you sure?'}</h2>
        <p><strong>{fighterA.first_name + " " + fighterA.last_name}</strong> vs <strong>{fighterB.first_name + " " + fighterB.last_name}</strong></p>
        <input type="hidden" name="fighterA" value={fighterA.id} />
        <input type="hidden" name="fighterB" value={fighterB.id} />
        <input type="hidden" name="weightClass" value={weightClass} />
        <input type="hidden" name="professional" value={professional} />
        {catchWeight && <div className={styles.confirmInput}>
          <label htmlFor="catchWeight"><strong>Catch Weight</strong> ({`${catchWeightRange.min}kg - ${catchWeightRange.max}kg`})</label>
          <input
            id="catchWeight"
            type="number"
            min={catchWeightRange.min}
            max={catchWeightRange.max}
            step="any"
            name="catchWeight"
            value={selectedCatchWeight}
            onChange={(event) => setSelectedCatchWeight(event.target.value)}
            required
          />
        </div>}
        {!catchWeight && <div className={styles.confirmInput}>
          <label htmlFor="titleFight">Title Fight?</label>
          <select id="titleFight" name="titleFight" value={titleFight} onChange={(event) => setTitleFight(event.target.value === 'true')}>
            <option value={false}>No</option>
            <option value={true}>Yes</option>
          </select>
        </div>}
        <div className={styles.confirmations}>
          <button className={styles.yesBtn} type="submit">Yes</button>
          <button className={styles.noBtn} type="button" onClick={onClose}>No</button>
        </div>
      </Form>
    </Modal>
  );
}

export default FightConfirmModal;

